<template>
  <div>
    <a-card>
      <template slot="title">
        <strong>Register Nasabah</strong>
        <a-range-picker class="ml-3" :format="'DD-MM-YYYY'" @change="rangedofunction"/>
        <a-button
          type="button"
          class="btn btn-outline-primary float-right"
          @click="connector"
        >
          Tambah Data
        </a-button>
      </template>
      <a-table
        :columns="columns"
        :dataSource="data"
        :scroll="{ x: 20000, y: 400 }"
        :pagination="{
          defaultPageSize: 10,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          pageSizeOptions: ['10', '20', '30'],
        }"
        size="small"
      >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px;"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px;"
            @click="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          >
            Search
          </a-button>
          <a-button
            size="small"
            style="width: 90px;"
            @click="() => handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        ></a-icon>
        <template
          slot="customRender"
          slot-scope="text, record, index, column"
        >
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(
                  new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                )"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            {{ text }}
          </template>
        </template>
        <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
        <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
        <span slot="tags" slot-scope="tags">
          <a-tag v-for="tag in tags" color="blue" :key="tag">{{ tag }}</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a class="text-warning" @click="sendToEdit(record)">Edit</a>
          <a-divider type="vertical" />
          <a href="javascript:void(0)" class="text-danger" @click="showDeleteConfirm(record)">Delete</a>
          <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
        </span>
        <span slot="statusperkawinan" slot-scope="text, record">
          {{ statusperkawinan[record.statusperkawinan] }}
        </span>
        <span slot="jenisidentitas" slot-scope="text, record">
          {{ jenisidentitas[record.jenisidentitas] }}
        </span>
        <span slot="seumurhidup" slot-scope="text, record">
          {{ yatidak[record.seumurhidup] }}
        </span>
        <span slot="blacklist" slot-scope="text, record">
          {{ yatidak[record.blacklist] }}
        </span>
        <span slot="perorangan" slot-scope="text, record">
          {{ perorangan[record.Perorangan] }}
        </span>
        <span slot="tgl" slot-scope="text, record">
          {{ formatDate(record.tgl) }}
        </span>
        <span slot="tgllahir" slot-scope="text, record">
          {{ formatDate(record.tgllahir) }}
        </span>
        <span slot="expiredidentitas" slot-scope="text, record">
          {{ formatDate(record.expiredidentitas) }}
        </span>
      </a-table>
    </a-card>
  </div>
</template>

<script>
/* Import Script */
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'
/* End Script Import Script */

/* Kolom atau header dari table */
const columns = [
  {
    title: 'Action',
    scopedSlots: { customRender: 'action' },
    width: 100,
  },
  {
    title: 'Nama',
    dataIndex: 'nama',
    key: 'nama',
    // width: 150,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.nama.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    dataIndex: 'kode',
    rowKey: 'kode',
    title: 'Kode',
    // width: 150,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.kode.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'agama',
    dataIndex: 'agama',
    scopedSlots: { customRender: 'agama' },
    // width: 100,
  },
  {
    title: 'aktebu',
    dataIndex: 'aktebu',
    scopedSlots: { customRender: 'aktebu' },
    // width: 100,
  },
  {
    title: 'akteperubahanbu',
    dataIndex: 'akteperubahanbu',
    scopedSlots: { customRender: 'akteperubahanbu' },
    // width: 100,
  },
  {
    title: 'aktif',
    dataIndex: 'aktif',
    scopedSlots: { customRender: 'aktif' },
    // width: 100,
  },
  {
    title: 'alamat',
    dataIndex: 'alamat',
    scopedSlots: { customRender: 'alamat' },
    // width: 100,
  },
  {
    title: 'alamatbu',
    dataIndex: 'alamatbu',
    scopedSlots: { customRender: 'alamatbu' },
    // width: 100,
  },
  {
    title: 'alamatkantor',
    dataIndex: 'alamatkantor',
    scopedSlots: { customRender: 'alamatkantor' },
    // width: 100,
  },
  {
    title: 'alamatlembaga',
    dataIndex: 'alamatlembaga',
    scopedSlots: { customRender: 'alamatlembaga' },
    // width: 100,
  },
  {
    title: 'alamattinggal',
    dataIndex: 'alamattinggal',
    scopedSlots: { customRender: 'alamattinggal' },
    // width: 100,
  },
  {
    title: 'ao',
    dataIndex: 'ao',
    scopedSlots: { customRender: 'ao' },
    // width: 100,
  },
  {
    title: 'badanhukum',
    dataIndex: 'badanhukum',
    scopedSlots: { customRender: 'badanhukum' },
    // width: 100,
  },
  {
    title: 'bagian',
    dataIndex: 'bagian',
    scopedSlots: { customRender: 'bagian' },
    // width: 100,
  },
  {
    title: 'bidangusaha',
    dataIndex: 'bidangusaha',
    scopedSlots: { customRender: 'bidangusaha' },
    // width: 100,
  },
  {
    title: 'bidangusahabu',
    dataIndex: 'bidangusahabu',
    scopedSlots: { customRender: 'bidangusahabu' },
    // width: 100,
  },
  {
    title: 'bu',
    dataIndex: 'bu',
    scopedSlots: { customRender: 'bu' },
    // width: 100,
  },
  {
    title: 'desatinggal',
    dataIndex: 'desatinggal',
    scopedSlots: { customRender: 'desatinggal' },
    // width: 100,
  },
  {
    title: 'din',
    dataIndex: 'din',
    scopedSlots: { customRender: 'din' },
    // width: 100,
  },
  {
    title: 'emailbu',
    dataIndex: 'emailbu',
    scopedSlots: { customRender: 'emailbu' },
    // width: 100,
  },
  {
    title: 'fax',
    dataIndex: 'fax',
    scopedSlots: { customRender: 'fax' },
    // width: 100,
  },
  {
    title: 'faxkantor',
    dataIndex: 'faxkantor',
    scopedSlots: { customRender: 'faxkantor' },
    // width: 100,
  },
  {
    title: 'faxtinggal',
    dataIndex: 'faxtinggal',
    scopedSlots: { customRender: 'faxtinggal' },
    // width: 100,
  },
  {
    title: 'foto2',
    dataIndex: 'foto2',
    scopedSlots: { customRender: 'foto2' },
    // width: 100,
  },
  {
    title: 'foto3',
    dataIndex: 'foto3',
    scopedSlots: { customRender: 'foto3' },
    // width: 100,
  },
  {
    title: 'gelar',
    dataIndex: 'gelar',
    scopedSlots: { customRender: 'gelar' },
    // width: 100,
  },
  {
    title: 'golongandarah',
    dataIndex: 'golongandarah',
    scopedSlots: { customRender: 'golongandarah' },
    // width: 100,
  },
  {
    title: 'golongandebiturbu',
    dataIndex: 'golongandebiturbu',
    scopedSlots: { customRender: 'golongandebiturbu' },
    // width: 100,
  },
  {
    title: 'hubunganljkbu',
    dataIndex: 'hubunganljkbu',
    scopedSlots: { customRender: 'hubunganljkbu' },
    // width: 100,
  },
  {
    title: 'ibukandung',
    dataIndex: 'ibukandung',
    scopedSlots: { customRender: 'ibukandung' },
    // width: 100,
  },
  {
    title: 'iddebitur',
    dataIndex: 'iddebitur',
    scopedSlots: { customRender: 'iddebitur' },
    // width: 100,
  },
  {
    title: 'jenisbu',
    dataIndex: 'jenisbu',
    scopedSlots: { customRender: 'jenisbu' },
    // width: 100,
  },
  {
    title: 'jumlahtanggungan',
    dataIndex: 'jumlahtanggungan',
    scopedSlots: { customRender: 'jumlahtanggungan' },
    // width: 100,
  },
  {
    title: 'kecamatan',
    dataIndex: 'kecamatan',
    scopedSlots: { customRender: 'kecamatan' },
    // width: 100,
  },
  {
    title: 'kecamatanbu',
    dataIndex: 'kecamatanbu',
    scopedSlots: { customRender: 'kecamatanbu' },
    // width: 100,
  },
  {
    title: 'kecamatanlembaga',
    dataIndex: 'kecamatanlembaga',
    scopedSlots: { customRender: 'kecamatanlembaga' },
    // width: 100,
  },
  {
    title: 'kecamatantinggal',
    dataIndex: 'kecamatantinggal',
    scopedSlots: { customRender: 'kecamatantinggal' },
    // width: 100,
  },
  {
    title: 'kelamin',
    dataIndex: 'kelamin',
    scopedSlots: { customRender: 'kelamin' },
    // width: 100,
  },
  {
    title: 'kelurahan',
    dataIndex: 'kelurahan',
    scopedSlots: { customRender: 'kelurahan' },
    // width: 100,
  },
  {
    title: 'kelurahanbu',
    dataIndex: 'kelurahanbu',
    scopedSlots: { customRender: 'kelurahanbu' },
    // width: 100,
  },
  {
    title: 'kelurahanlembaga',
    dataIndex: 'kelurahanlembaga',
    scopedSlots: { customRender: 'kelurahanlembaga' },
    // width: 100,
  },
  {
    title: 'keterkaitan',
    dataIndex: 'keterkaitan',
    scopedSlots: { customRender: 'keterkaitan' },
    // width: 100,
  },
  {
    title: 'kewarganegaraan',
    dataIndex: 'kewarganegaraan',
    scopedSlots: { customRender: 'kewarganegaraan' },
    // width: 100,
  },
  {
    title: 'kodepos',
    dataIndex: 'kodepos',
    scopedSlots: { customRender: 'kodepos' },
    // width: 100,
  },
  {
    title: 'kodeposbu',
    dataIndex: 'kodeposbu',
    scopedSlots: { customRender: 'kodeposbu' },
    // width: 100,
  },
  {
    title: 'kodeposkantor',
    dataIndex: 'kodeposkantor',
    scopedSlots: { customRender: 'kodeposkantor' },
    // width: 100,
  },
  {
    title: 'kodepostinggal',
    dataIndex: 'kodepostinggal',
    scopedSlots: { customRender: 'kodepostinggal' },
    // width: 100,
  },
  {
    title: 'kodya',
    dataIndex: 'kodya',
    scopedSlots: { customRender: 'kodya' },
    // width: 100,
  },
  {
    title: 'kodyabu',
    dataIndex: 'kodyabu',
    scopedSlots: { customRender: 'kodyabu' },
    // width: 100,
  },
  {
    title: 'kodyalembaga',
    dataIndex: 'kodyalembaga',
    scopedSlots: { customRender: 'kodyalembaga' },
    // width: 100,
  },
  {
    title: 'kodyatinggal',
    dataIndex: 'kodyatinggal',
    scopedSlots: { customRender: 'kodyatinggal' },
    // width: 100,
  },
  {
    title: 'kotapendirian',
    dataIndex: 'kotapendirian',
    scopedSlots: { customRender: 'kotapendirian' },
    // width: 100,
  },
  {
    title: 'ktp',
    dataIndex: 'ktp',
    scopedSlots: { customRender: 'ktp' },
    // width: 100,
  },
  {
    title: 'ktppasangan',
    dataIndex: 'ktppasangan',
    scopedSlots: { customRender: 'ktppasangan' },
    // width: 100,
  },
  {
    title: 'maksudtujuan',
    dataIndex: 'maksudtujuan',
    scopedSlots: { customRender: 'maksudtujuan' },
    // width: 100,
  },
  {
    title: 'nama_pasangan',
    dataIndex: 'nama_pasangan',
    scopedSlots: { customRender: 'nama_pasangan' },
    // width: 100,
  },
  {
    title: 'namaalias',
    dataIndex: 'namaalias',
    scopedSlots: { customRender: 'namaalias' },
    // width: 100,
  },
  {
    title: 'namabu',
    dataIndex: 'namabu',
    scopedSlots: { customRender: 'namabu' },
    // width: 100,
  },
  {
    title: 'namaibukandung',
    dataIndex: 'namaibukandung',
    scopedSlots: { customRender: 'namaibukandung' },
    // width: 100,
  },
  {
    title: 'namalembaga',
    dataIndex: 'namalembaga',
    scopedSlots: { customRender: 'namalembaga' },
    // width: 100,
  },
  {
    title: 'namapasangan',
    dataIndex: 'namapasangan',
    scopedSlots: { customRender: 'namapasangan' },
    // width: 100,
  },
  {
    title: 'nik_pasangan',
    dataIndex: 'nik_pasangan',
    scopedSlots: { customRender: 'nik_pasangan' },
    // width: 100,
  },
  {
    title: 'noaktependirian',
    dataIndex: 'noaktependirian',
    scopedSlots: { customRender: 'noaktependirian' },
    // width: 100,
  },
  {
    title: 'nonpwp',
    dataIndex: 'nonpwp',
    scopedSlots: { customRender: 'nonpwp' },
    // width: 100,
  },
  {
    title: 'nopaspor',
    dataIndex: 'nopaspor',
    scopedSlots: { customRender: 'nopaspor' },
    // width: 100,
  },
  {
    title: 'npwp',
    dataIndex: 'npwp',
    scopedSlots: { customRender: 'npwp' },
    // width: 100,
  },
  {
    title: 'npwpbu',
    dataIndex: 'npwpbu',
    scopedSlots: { customRender: 'npwpbu' },
    // width: 100,
  },
  {
    title: 'path',
    dataIndex: 'path',
    scopedSlots: { customRender: 'path' },
    // width: 100,
  },
  {
    title: 'path1',
    dataIndex: 'path1',
    scopedSlots: { customRender: 'path1' },
    // width: 100,
  },
  {
    title: 'pekerjaan',
    dataIndex: 'pekerjaan',
    scopedSlots: { customRender: 'pekerjaan' },
    // width: 100,
  },
  {
    title: 'pendapatanratarata',
    dataIndex: 'pendapatanratarata',
    scopedSlots: { customRender: 'pendapatanratarata' },
    // width: 100,
  },
  {
    title: 'rtrw',
    dataIndex: 'rtrw',
    scopedSlots: { customRender: 'rtrw' },
    // width: 100,
  },
  {
    title: 'rtrwtinggal',
    dataIndex: 'rtrwtinggal',
    scopedSlots: { customRender: 'rtrwtinggal' },
    // width: 100,
  },
  {
    title: 'status',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
    // width: 100,
  },
  {
    title: 'statuskirim',
    dataIndex: 'statuskirim',
    scopedSlots: { customRender: 'statuskirim' },
    // width: 100,
  },
  {
    title: 'statusperkawinan',
    dataIndex: 'statusperkawinan',
    scopedSlots: { customRender: 'statusperkawinan' },
    // width: 100,
  },
  {
    title: 'statussid',
    dataIndex: 'statussid',
    scopedSlots: { customRender: 'statussid' },
    // width: 100,
  },
  {
    title: 'sumberdana',
    dataIndex: 'sumberdana',
    scopedSlots: { customRender: 'sumberdana' },
    // width: 100,
  },
  {
    title: 'tanggalpendirian',
    dataIndex: 'tanggalpendirian',
    scopedSlots: { customRender: 'tanggalpendirian' },
    // width: 100,
  },
  {
    title: 'telepon',
    dataIndex: 'telepon',
    scopedSlots: { customRender: 'telepon' },
    // width: 100,
  },
  {
    title: 'teleponbu',
    dataIndex: 'teleponbu',
    scopedSlots: { customRender: 'teleponbu' },
    // width: 100,
  },
  {
    title: 'teleponkantor',
    dataIndex: 'teleponkantor',
    scopedSlots: { customRender: 'teleponkantor' },
    // width: 100,
  },
  {
    title: 'teleponlembaga',
    dataIndex: 'teleponlembaga',
    scopedSlots: { customRender: 'teleponlembaga' },
    // width: 100,
  },
  {
    title: 'telepontinggal',
    dataIndex: 'telepontinggal',
    scopedSlots: { customRender: 'telepontinggal' },
    // width: 100,
  },
  {
    title: 'tempatbu',
    dataIndex: 'tempatbu',
    scopedSlots: { customRender: 'tempatbu' },
    // width: 100,
  },
  {
    title: 'tempatlahir',
    dataIndex: 'tempatlahir',
    scopedSlots: { customRender: 'tempatlahir' },
    // width: 100,
  },
  {
    title: 'tempatlahir_pasangan',
    dataIndex: 'tempatlahir_pasangan',
    scopedSlots: { customRender: 'tempatlahir_pasangan' },
    // width: 100,
  },
  {
    title: 'tglbu',
    dataIndex: 'tglbu',
    scopedSlots: { customRender: 'tglbu' },
    // width: 100,
  },
  {
    title: 'tglktp',
    dataIndex: 'tglktp',
    scopedSlots: { customRender: 'tglktp' },
    // width: 100,
  },
  {
    title: 'tgllahir',
    dataIndex: 'tgllahir',
    scopedSlots: { customRender: 'tgllahir' },
    // width: 100,
  },
  {
    title: 'tgllahir_pasangan',
    dataIndex: 'tgllahir_pasangan',
    scopedSlots: { customRender: 'tgllahir_pasangan' },
    // width: 100,
  },
  {
    title: 'tgllahirpasangan',
    dataIndex: 'tgllahirpasangan',
    scopedSlots: { customRender: 'tgllahirpasangan' },
    // width: 100,
  },
  {
    title: 'tglperubahanbu',
    dataIndex: 'tglperubahanbu',
    scopedSlots: { customRender: 'tglperubahanbu' },
    // width: 100,
  },
  {
    title: 'tglregister',
    dataIndex: 'tglregister',
    scopedSlots: { customRender: 'tglregister' },
    // width: 100,
  },
  {
    title: 'updatedate',
    dataIndex: 'updatedate',
    scopedSlots: { customRender: 'updatedate' },
    // width: 100,
  },
]
/* End script Kolom atau header dari table */

/* Data dari table */
const data = []
/* End script Data dari table */

export default {
  /* Data variable vue */
  data() {
    return {
      searchText: '',
      tgl1: '',
      tgl2: '',
      searchInput: null,
      searchedColumn: '',
      name: 'TableRegisterNasabah',
      visible: false,
      statusperkawinan: {
        K: 'Kawin',
        B: 'Belum Kawin',
        D: 'Duda',
        J: 'Janda',
      },
      jenisidentitas: {
        1: 'KTP',
        2: 'SIM',
        3: 'Pasport',
      },
      yatidak: {
        0: 'Tidak',
        1: 'Ya',
      },
      perorangan: {
        1: 'Perorangan',
        2: 'Non-Perorangan',
      },
      dataFagama: [],
      data,
      columns,
      datenow: '',
    }
  },
  /* End Data variable vue */
  mounted: function () {
    const date = moment().format('YYYY-MM-DD') // Tanggal hari ini format 2020-12-31
    localStorage.removeItem('cbseditregisternasabahdata')
    this.tgl1 = date
    this.tgl2 = date
    this.datenow = date.toString()
    this.getAllData()
    this.getAgamaF()
  },
  methods: {
    moment,
    connector() {
      localStorage.setItem('cbseditregisternasabahdata', JSON.stringify({ id: '' }))
      this.$router.push('/cbstdn/registernasabah/form')
    },
    rangedofunction(date, dateString) {
      this.tgl1 = moment(dateString[0], 'DD-MM-YYYY').format('YYYY-MM-DD')
      this.tgl2 = moment(dateString[1], 'DD-MM-YYYY').format('YYYY-MM-DD')
      this.getAllData()
    },
    async getAllData() {
      var response = await lou.customUrlGet('registernasabah')
      if (response) {
        this.data = response.data
      }
    },
    async getAgamaF() {
      // var res = await lou.customUrlGet('agama')
      // this.dataFagama = res.data
      // this.$perpus.searchObj()
    },
    sendToEdit(edata) {
      // var ndata = {}
      // for (let i = 0; i < this.columns.length; i++) {
      //   const element = this.columns[i].dataIndex
      //   ndata[element.toString().toLowerCase()] = edata[element]
      // }
      localStorage.setItem('cbseditregisternasabahdata', JSON.stringify(edata))
      this.$router.push('/cbstdn/registernasabah/form')
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete('registernasabah', deldata.id)
          this.data = response.data
          this.getAllData()
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
  },
}
</script>

<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
